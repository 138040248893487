/* ==========================================================================
Main SCSS file / Fresh
========================================================================== */

//Imports
@import 'partials/fonts';
@import 'partials/colors';
@import 'partials/navbar';
@import 'partials/dropdowns';
@import 'partials/sections';
@import 'partials/hero';
@import 'partials/footer';
@import 'partials/buttons';
@import 'partials/cards';
@import 'partials/forms';
@import 'partials/animations';
@import 'partials/sidebar';
@import 'partials/testimonials';
@import 'partials/responsive';
@import 'partials/utils';
