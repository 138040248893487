/* ==========================================================================
Hero styles
========================================================================== */

.hero-body {
    padding-top: 6rem;
    padding-bottom: 6rem;
    .title, .subtitle {
        font-family: "Noto Sans", BlinkMacSystemFont, -apple-system, "Helvetica", "Arial", sans-serif;
    }
    .title {
        &.is-bold {
            font-weight: 700;
        }
    }
    .subtitle {
        &.is-muted {
            color: $muted-grey;
        }
    }
}

.hero-foot {
    img.partner-logo {
        height: 70px;
    }
}
