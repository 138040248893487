$family-sans-serif: "Noto Sans", BlinkMacSystemFont, -apple-system, "Helvetica", "Arial", sans-serif;

$family-serif: "Noto Serif", BlinkMacSystemFont, -apple-system, "Helvetica", "Arial", serif;


$family-text: $family-serif;
$family-primary: $family-sans-serif;


%text {
    font-family: $family-primary;
    font-weight: 500;
}

p {
    @extend %text;
}
